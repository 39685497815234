<template>
  <div class="events-table" v-if="ready">
    <div class="content" v-if="hasAtLeastOne">
      <!--      Table Header-->
      <CRow>
        <CCol col="12">
          <TableHeader
            :title="texts.pageTitle"
            :isReloadVisible="true"
            :originalFields="originalFields"
            :fields="tableFields"
            :statuses="statuses"
            :disabled="!ready"
            :isVisible="selectedRecords.length"
            @updateFields="updateFields"
            @createItem="openModal"
            @toggleFilter="showColumnFilter"
            @checkStatus="checkStatus"
            @searchData="searchEvents"
            @clearFilter="clearFilter"
            @reload="reload"
            @deleteData="deleteData"
          />
        </CCol>
      </CRow>
      <!--   Table Body   -->
      <CRow class="mt-1">
        <CCol col="12">
          <CCard class="zq-card-table">
            <CCardBody>
              <CDataTable
                ref="eventTable"
                id="eventTable"
                :items="eventsData"
                :fields="tableFields"
                class="zq--table"
                :class="{ 'is-show-filter': columnFilter }"
                :column-filter="columnFilter"
                :items-per-page="itemsPerPage"
                :sorter="{ external: true }"
                :responsive="true"
                :border="isDefaultTheme"
                :hover="isDefaultTheme"
                :striped="isDefaultTheme"
                :loading="loading"
                @pagination-change="itemsPerPageSelect"
                @page-change="paginationChange"
                @update:sorter-value="handleSortBy"
                @update:column-filter-value="handleColumnFilter"
                :sorter-value.sync="sorterValue"
              >
                <template #loading>
                  <TableLoader/>
                </template>
                <template #id="{ item }">
                  <td
                    :class="{'column-shadow-start': isTableScrollbar}"
                  >
                    <router-link :to="{name: `PreviewEvent`, params: {id: item.id} }">
                      {{ item.id }}
                    </router-link>
                  </td>
                </template>
                <template #spaceName="{ item }">
                  <td>{{ getSpaceName(item.spaceName) }}</td>
                </template>
                <template #entityId="{ item }">
                  <td>
                    <a href="#" @click.prevent="goToEntityPreview(item.entityId, item.action)">{{ item.entityId }}</a>
                  </td>
                </template>
                <template #memberId="{ item }">
                  <td>
                    <router-link :to="{name: `PreviewMember`, params: {id: item.memberId} }">
                      {{ item.memberId }}
                    </router-link>
                  </td>
                </template>
                <template #created="{item}">
                  <td>{{ dateFormate(item.created) }}</td>
                </template>
                <template #transactionTimestamp="{item}">
                  <td>{{ dateFormate(item.transactionTimestamp) }}</td>
                </template>
                <template #metadata="{item}">
                  <td>{{ getMetaData(item.metadata) }}</td>
                </template>
                <template #actions="{ item }">
                  <ClTableActionsDropdown
                    @choice="dropDownChoice"
                    :itemProp="item"
                    :actionsProp="dropDownActions"
                    :isTableScrollbarProp="isTableScrollbar"
                  />
                </template>
                <!--       Table Filters         -->
                <template #actions-header>
                  <div :class="{ 'reduction-row-actions': isTableScrollbar }" v-theme-header-actions>Actions</div>
                </template>
                <!--     Actions  Filter      -->
                <template #actions-filter>
                  <div></div>
                </template>
                <template #select-header class="text-center">
                  <div
                    class="position-relative zq--table-select-all"
                    v-theme-select-all
                  >
                    <ClCheckbox
                      :checkedProp="toggleFlag"
                      icon="fa-angle-down"
                      @handler="selectAll"
                    />
                  </div>
                </template>
                <template #select="{ item }">
                  <td
                    :data-id="item.id"
                    :class="{ 'column-shadow-start': isTableScrollbar }"
                    class="text-center nth-select select-td fixed-column-start"
                  >
                    <ClCheckbox
                      :valueProp="item.id"
                      :checkedProp="selectedRecords.indexOf(item.id) > -1"
                      @handler="checkRecords"
                    />
                  </td>
                </template>
              </CDataTable>
              <TableFooter
                :page="page"
                @updatePagenation="paginationChange"
                :pages="pages"
                :total="totalRecords"
                :itemsPerPage="itemsPerPage"
                :disabled="!ready"
                @updateItemPerPage="itemsPerPageSelect"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <section class="no-content-wrap" v-else>
      <div class="d-flex justify-content-center flex-column align-items-center">
        <h6 class="no-members--title">{{ eventCreateOrLoadConfig.title }}</h6>

        <slot name="menu"></slot>

        <CDropdown
          v-if="!this.$slots.menu"
          placement="bottom-end"
          add-menu-classes="pt-0"
        >
          <template #toggler class="justify-content-end">
            <CButton class="create-or-load-action-btn" color="dark" variant="outline"> Actions <i class="fa fa-caret-down"></i></CButton>
          </template>
          <CDropdownItem class="p-0">
            <CButton
              pill
              color="dark"
              class="action-custom-button"
              variant="ghost"
              @click="openModal"
            >
              {{ eventCreateOrLoadConfig.createTitle }}
            </CButton>
          </CDropdownItem>
        </CDropdown>
      </div>
    </section>
    <!-- CREATE EVENT MODAL-->
    <Modal
      :modalShow="showModal"
      :title="texts.createPopup.title"
      @doFunction="createEvent"
      v-on:toggle-modal="closeModal"
      :cancel-btn-label="texts.createPopup.cancelBtn"
      :success-btn-label="texts.createPopup.createBtn"
      :sub-title="texts.createPopup.subTitle"
      :loading="modalLoading"
    >
      <template #loading>
        <TableLoader/>
      </template>
      <template #body>
        <LoadFromJson v-on:change-json="loadJson" />
      </template>
    </Modal>
    <!-- DELETE  MODAL   -->
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="messageGeneral"
      :title="deleteTitle"
      @doFunction="deleteSelectedField"
      v-on:toggle-modal="closeModal('delete')"
    />
  </div>
  <PreviewSpiner
    v-else
    :texts="['Validating...', 'Preparing...', 'Redirecting...']"
    :delay="5000"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {dateFormate} from '@/utils/dateFormate';
import LoadFromJson from '@/components/LoadFromJson'
import { pageConfig } from '@/config';
import Modal from '@/shared/UI/Modal';
import TableHeader from '@/components/table/Header';
import TableFooter from '@/components/table/Footer';
import TableLoader from "@/components/table/Loader";
import ClTableActionsDropdown from '@/shared/components/ClTableActionsDropdown';
import { checkNullItem } from "@/helpers/checkNullItem";
import { eventsTexts } from "@/config/pageTexts/events";
import { delay, cloneDeep } from 'lodash';
import TableResizeMixin from '@/shared/mixins/TableResizeMixin';
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox';
import PreviewSpiner from "@/shared/UI/Spiner.vue";

export default {
  components: {
    PreviewSpiner,
    TableHeader,
    TableFooter,
    LoadFromJson,
    Modal,
    TableLoader,
    ClTableActionsDropdown,
    ClCheckbox
  },
  data() {
    return {
      texts: {
        ...eventsTexts
      },
      eventsData: [],
      jsonEvents: null,
      details: [],
      collapseDuration: 0,
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      showModal: false,
      ready: false,
      isTableScrollbar: false,
      previewEvent: {},
      columnFilter: false,
      modalLoading: false,
      actions: 2,
      status: 'All',
      statuses: [],
      columnFilterFields: [
        'id-filter',
        'created-filter',
        'spaceName-filter',
        'memberId-filter',
        'memberRefId-filter',
        'action-filter',
        'entityId-filter',
        'entityRefId-filter',
        'sourceValue-filter',
        'points-filter',
        'transactionTimestamp-filter',
        'metadata-filter'
      ],
      tableFields: [],
      searchableFields: ['id','action', 'unitOfMeasure', 'memberId', 'memberRefId', 'batchId', 'entityId', 'eventRefId', 'entityRefId', 'tags'],
      eventCreateOrLoadConfig: {
        title: eventsTexts.welcome.title,
        createTitle: eventsTexts.welcome.createTitle,
        path: 'Create Event'
      },
      hasAtLeastOne: true,
      dropDownActions: [
        {
          title: this.$t('buttons.preview'),
          handler: 'showPreview',
          disabled: false,
        },
        {
          title: this.$t('buttons.edit'),
          handler: 'editTag',
          disabled: true,
        },
        {
          title: this.$t('buttons.delete'),
          handler: 'handleDeleteModal',
          disabled: true,
        }
      ],
      deleteModal: false,
      toggleFlag: false,
      selectedRecords: [],
      deleteTitle: 'Delete Event',
      messageGeneral: `You are about to delete an event , Are you sure?`
    };
  },
  watch: {
    events(val) {
      // this.hasAtLeastOne = !!val.length;
      this.eventsData = checkNullItem(val);
    },
    fields(val) {
      this.tableFields = cloneDeep(val);
      const uomIdx = this.tableFields.findIndex(f => f === 'unitOfMeasure');
      const sourceValueIdx = this.tableFields.findIndex(f => f === 'sourceValue');
      this.tableFields.splice(uomIdx, 1);
      this.tableFields.splice(sourceValueIdx, 0, 'unitOfMeasure');
    },
    columnFilter(val) {
      if (val) {
        this.$nextTick(() => this.disableColumnFilters());
      }
    }
  },
  created() {
    this.initialize();
    this.eventsData = checkNullItem(this.events);
  },
  computed: {
    ...mapGetters('events', [
      'events',
      'fields',
      'pages',
      'loading',
      'resultCount',
      'totalRecords',
      'originalFields',
      'searchableAllFields'
    ]),
    ...mapGetters('theme', ['theme']),
    ...mapGetters('spaces', ['spaces']),
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc'
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        }
      }
    },
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  mixins: [
    TableResizeMixin('eventTable')
  ],
  methods: {
    ...mapActions('events', [
      'handleFields',
      'handleClearFilter',
      'handleCreateEvents',
      'handleGetEventsByQuery',
      'handleDeleteEvents'
    ]),
    async initialize() {
      const data = await this.queryDB();

      if (!data.length) this.hasAtLeastOne = false;

      if (data) this.ready = true;

      this.handleFields();
    },
    disableColumnFilters() {
      const filterInputs = this.$refs.eventTable?.$el.querySelectorAll('[aria-label*="filter input"]');

      filterInputs?.forEach(input => {
        const columnName = input.getAttribute('aria-label')?.match(/column name: '(.+?)'/)?.[1];
        if (columnName && !this.searchableFields.includes(columnName)) {
          input.disabled = true;
          input.style.cursor = "not-allowed";
          input.style.opacity = "0.9";
        }
      });
    },
    async itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      await this.queryDB();
    },
    async paginationChange(val) {
      this.page = val;
      await this.queryDB();
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    getMetaData(metaData) {
      let meta = '';
      const maxlength = 20;
      if (metaData && Object.values(metaData).length > 0) {
        Object.values(metaData).forEach(element => {
          meta += element + '\n';
        });
      }
      return meta.length > maxlength
          ? meta.substring(0, maxlength - 3) + '...'
          : meta.substring(0, maxlength);
    },
    showDetails(item) {
      this.$router.push({
        name: 'PreviewEvent',
        params: {
          id: item.id
        }
      })
    },
    updateFields(val) {
      this.handleFields(val);
    },
    clearFilter() {
      this.handleClearFilter();
    },
    async createEvent() {
      this.modalLoading = true;
      const body = JSON.parse(JSON.stringify(this.jsonEvents));
      this.ready = true
      const eventData = await this.handleCreateEvents({createEventRequestArray: body});
      if (eventData.length) {
        this.ready = false;
        delay(() => {
          this.modalLoading = false;
          this.$router.push({
            name: 'PreviewEvent',
            params: {
              id: eventData[0].id
            }
          })
        }, 15000);
      }
    },
    loadJson(val) {
      this.jsonEvents = val;
    },
    showColumnFilter(val) {
      this.columnFilter = val;
      if (!val) {
        this.formatFilter();
      }
    },
    checkStatus(val) {
      this.status = val;
    },
    async formatFilter() {
      this.$refs.eventTable.clean();
      this.page = 1;
      this.query = {};
      this.sortBy = [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ];
      await this.queryDB();
    },
    async handleSortBy(e) {
      this.sortBy = [
        {
          queryField: e.column,
          order: e.asc ? 'Asc' : 'Desc',
        },
      ];
      this.page = 1;
      await this.queryDB();
    },
    async handleColumnFilter(e) {
      const columnFilterName = Object.keys(e)[Object.keys(e).length - 1];
      this.multiFields = [];
      let str = "";
      Object.keys(e).forEach((x) => {
        if (e[x] !== ' ') {
          str = e[x].trim();
          if(str.length > 0) {
            // this.sortBy = [
            //   {
            //     queryField: '_score',
            //     order: 'Desc',
            //   },
            // ];
            this.multiFields.push({
              queryFields: [x],
              queryValue: str,
            });
          } else {
            return false;
          }
        }
      });
      await this.queryDB();

      if (this.$refs.eventTable && this.$refs.eventTable.$el) {
        this.$refs.eventTable.$el
          .querySelector('[aria-label="column name: \'' + columnFilterName +'\' filter input"]')
          .focus();
      }
    },
    async searchEvents(val) {
      this.columnFilter = false;
      if (this.status && this.status.toLowerCase() !== 'all') {
        this.must = [
          {
            queryField: 'status',
            queryValues: [this.status]
          }
        ];
      }
      // this.sortBy = [
      //   {
      //     queryField: '_score',
      //     order: 'Desc',
      //   },
      // ];
      this.multiFields = [];
      if (val.trim() !== '') {
        this.multiFields.push({
          queryFields: this.searchableAllFields,
          queryValue: val.trim(),
        });
      }
      await this.queryDB();
    },
    goToEntityPreview(id, action) {
      if (action.includes('ziqni.achievement.')) {
        this.$router.push({
          name: 'PreviewAchievement',
          params: {
            id: id,
          }
        });
      } else if (action.includes('ziqni.competition.')) {
        this.$router.push({
          name: 'PreviewCompetition',
          params: {
            id: id,
          }
        });
      } else if (action.includes('ziqni.contest.')) {
        this.$router.push({
          name: 'PreviewContest',
          params: {
            id: id,
          }
        });
      } else if (action.includes('ziqni.award.')) {
        this.$router.push({
          name: 'PreviewAward',
          params: {
            id: id,
          }
        });
      } else if (action.includes('ziqni.reward.')) {
        this.$router.push({
          name: 'PreviewReward',
          params: {
            id: id,
          }
        });
      } else {
        this.$router.push({
          name: 'PreviewProduct',
          params: {
            id: id,
          }
        });
      }
    },
    openModal() {
      this.jsonEvents[0].transactionTimestamp = new Date();
      this.showModal = true;
    },
    closeModal() {
      this.modalLoading = false;
      this.showModal = false
    },
    reload() {
      this.initialize();
    },
    dropDownChoice(action, item) {
      if (this[action]) {
        this[action](item)
      }
    },
    selectAll() {
      this.toggleFlag = !this.toggleFlag;
      if (this.toggleFlag)
        this.events.forEach((item) => this.selectedRecords.push(item.id));
      else this.selectedRecords = [];
    },
    checkRecords({ event }) {
      const index = this.selectedRecords.indexOf(event.target.value);
      if (index > -1) {
        this.selectedRecords.splice(index, 1);
      } else {
        this.selectedRecords.push(event.target.value);
      }
    },
    async deleteSelectedField() {
      this.deleteModal = false;
      await this.handleDeleteEvents({idArray: [this.selectedId]});
      this.selectedRecords = [];
      this.toggleFlag = false;
      delay(async () => {
        this.tableData = cloneDeep([]);
        this.multiFields = [];
        await this.queryDB();
      }, 1000);
    },
    async queryDB() {
      const data = await this.handleGetEventsByQuery({queryRequest:
        {
          multiFields: this.multiFields,
          sortBy: this.sortBy,
          limit: this.itemsPerPage,
          skip: (this.page - 1) * this.itemsPerPage
        }
      });
      this.tableData = this.events;
      if (this.tableData.length) {
        this.tableData = checkNullItem(this.tableData);
      }
      return data;
    },
    deleteData() {
      this.messageGeneral =
          'You are about to delete ' +
          this.selectedRecords.length +
          ' units Of Measure , Are you sure?';
      this.deleteModal = true;
      this.selectedId = cloneDeep(this.selectedRecords);
    },
    handleDeleteModal(item) {
      this.deleteModal = true;
      this.selectedId = [item.id];
      this.messageGeneral = `You are about to delete an event , Are you sure?`;
    },
    showPreview(item) {
      this.$router.push({
        name: 'PreviewEvent',
        params: {
          id: item.id,
          name: item.name,
        },
      });
    },
    getSpaceName(id) {
      let name = id;

      const space = this.spaces.find((s) => s.id === id);
      if (space) {
        name = space.spaceName;
      }

      return name;
    },
  },
};
</script>


<style lang="scss">
.events-table {
  height: 100%;
  background: var(--zq-sub-bg);
  border-top: var(--zq-content-border-color);

  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }
}
#eventTable {
  .table {
    td:first-child {
      &:after {
        left: 16px;
      }

      &:before {
        left: 16px;
      }
    }
  }
}
.create-or-load-action-btn {
  border-radius: 12px;
}
.no-content-wrap {
  display: flex;
  justify-content: center;
  align-content: center;
  height: calc(100vh - 271px);
  background: var(--zq-sub-bg);
  .action-custom-button {
    width: 100%;
  }
  h6 {
    text-align: center;
    margin-bottom: 1%;
  }
}
</style>
